import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import useSlide from "../hooks/useSlide"
import parse from "html-react-parser"

type ParseType = {
  props: {
    children: {
      props: {
        src: string
        href: string
        children: {
          props: {
            src: string
          }
        }
      }
    }
  }
}

type SlideType = {
  type: "a" | "img"
  a?: { href: string }
  src: string
}

const Slide = () => {
  const { load, slides } = useSlide()
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  //! Any使用
  const selectFigureSrc = (html: any) => {
    let figureSrc: SlideType[] = []
    let d: SlideType
    typeof html == "object" &&
      html.forEach((e: ParseType, i: number) => {
        if (typeof e == "object") {
          if (e.props.children.props.src !== undefined) {
            d = {
              type: "img",
              src: e.props.children.props.src,
            }
            figureSrc.push(d)
          } else if (e.props.children.props.href !== undefined) {
            d = {
              type: "a",
              a: { href: e.props.children.props.href },
              src: e.props.children.props.children.props.src,
            }
            figureSrc.push(d)
          }
        }
      })
    return SlideElements(figureSrc)
  }

  const SlideElements = (slides: SlideType[]) => {
    return slides.map((slide, i) => {
      if (slide.type === "img") {
        return (
          <div key={i}>
            <img src={slide.src} alt="" />
          </div>
        )
      } else if (slide.type === "a") {
        return (
          <a
            href={slide.a?.href}
            target="_blank"
            key={i}
            rel="noopener noreferrer"
          >
            <div>
              <img src={slide.src} alt="" />
            </div>
          </a>
        )
      }
      return <></>
    })
  }

  return (
    <div id="slider">
      <Slider {...settings}>{load && selectFigureSrc(parse(slides))}</Slider>
    </div>
  )
}

export default Slide
