import { useEffect, useState } from "react"
import { WP_SLIDE_URL } from "../constants/url"

const useSlide = () => {
  const [slides, setSlides] = useState<string>("")
  const [load, setLoad] = useState(false)

  useEffect(() => {
    fetch(WP_SLIDE_URL)
      .then((res) => res.json())
      .then((d) => {
        setSlides(d["content"]["rendered"])
        setTimeout(() => {
          setLoad(true)
        }, 1000)
      })
      .catch(() => console.error())

    return () => {
      setSlides("")
      setLoad(false)
    }
  }, [])

  return { load, slides }
}

export default useSlide
