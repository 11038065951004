import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { WP_NEWS_URL } from "../constants/url"
import { useHistory } from "react-router-dom"
import "../styles/news.css"

type Params = {
  id: string
}
type NewsType = {
  title: {
    rendered: string
  }
  content: {
    rendered: string
  }
}

const useNews = () => {
  const [news, setNews] = useState<NewsType>({
    title: { rendered: "" },
    content: { rendered: "" },
  })
  const [load, setLoad] = useState<boolean>(false)
  const params: Params = useParams()
  const history = useHistory()

  useEffect(() => {
    fetch(`${WP_NEWS_URL}/${params.id}`)
      .then((res) => res.json())
      .then((d: NewsType) => {
        setNews(d)
        setTimeout(() => {
          setLoad(true)
        }, 1000)
      })
      .catch(() => {
        console.error()
        history.push("/")
      })
  }, [params.id, history])

  return { load, news }
}

export default useNews
