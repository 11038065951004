import { Link } from "react-router-dom"
import React from "react"
import { BubbleLoading } from "../constants/bubbleLoading"
import useProductList from "../hooks/useProductList"

const ProductList = () => {
  const { load, titles } = useProductList()
  return (
    <div id="product-list">
      {load ? (
        <div>
          {titles}
          <p>
            他にも日替わり商品など、さまざまな商品をご用意しております。
            <Link to="">
              <span>詳しくはこちら。</span>
            </Link>
          </p>
        </div>
      ) : (
        <BubbleLoading />
      )}
    </div>
  )
}

export default ProductList
