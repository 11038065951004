import "./styles/main.css"
import { Switch, Route, Link } from "react-router-dom"
import Posts from "./components/posts"
import News from "./pages/news"
import NewsList from "./pages/newsList"
import Slide from "./components/slide"
import ProductList from "./components/productList"
import Ad from "./components/ad"
import { MAP_SEN_NO_TORI } from "./constants/iframes"
import { AnimatePresence } from "framer-motion"
import FramerMotion from "./components/framerMotion"
import InstagramIcon from "./assets/images/logo/insta.png"
import SenNoTori_Main from "./assets/images/logo/sennotori_logo-01.png"
import Product from "./pages/product"
import Xmas from "./assets/images/xmas/ad.png"

const Main = () => {
  return (
    <div className="main-contents">
      <main>
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route path="/" exact>
                  <FramerMotion>
                    <section id="top">
                      <Slide />
                      <Posts />
                      <div id="sen-no-tori">
                        <img
                          className="main-logo"
                          src={SenNoTori_Main}
                          alt=""
                        />
                        <p className="top-text">
                          鶏肉専門店が手がける <br />
                          鶏肉や卵などの <br />
                          お惣菜・お弁当・sweets <br />
                          を販売しております。
                        </p>
                        <Ad />
                        <h2>おすすめ商品</h2>
                        <ProductList />
                        <h2>オンライン予約販売サイト</h2>
                        <a
                          href="https://shop.torishigehonten.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="tac">
                            <img src={Xmas} alt="" />
                          </div>
                        </a>
                        <p>クリスマス期間限定商品の予約販売を承ります。</p>
                        <p>
                          （予約開始日 <s>11/5（金）〜</s>）
                          <br />
                          <span className="error">
                            現在開始日が未定となっております。大変申し訳ございません。
                          </span>
                        </p>
                        <p>
                          <small>
                            ※千の鶏隣接の「鳥重本店」店頭にてご予約商品お受け取りとなります。
                            商品の配送はございませんのでご了承ください。
                          </small>
                        </p>
                        <h2>アクセス</h2>
                        <MAP_SEN_NO_TORI />
                        <div id="sns">
                          <h2>Instagram</h2>
                          <a
                            href="https://www.instagram.com/sennotori_0309/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <p>
                              <span className="id">@sennotori_0309</span>
                            </p>
                            <img src={InstagramIcon} alt="" />
                          </a>
                        </div>
                      </div>
                      <h2>お問い合わせ</h2>
                      <Link to="/contact/">
                        <p>こちらからお問い合わせください。</p>
                      </Link>
                    </section>
                  </FramerMotion>
                </Route>
                <Route path="/news/" exact>
                  <FramerMotion>
                    <h2>お知らせ一覧</h2>
                    <NewsList />
                  </FramerMotion>
                </Route>
                <Route path="/news/:id">
                  <FramerMotion>
                    <News />
                  </FramerMotion>
                </Route>
                <Route path="/products/:id">
                  <Product />
                </Route>
              </Switch>
            </AnimatePresence>
          )}
        />
      </main>
    </div>
  )
}
export default Main
