import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { WP_PRODUCT_URL } from "../constants/url"
import { useHistory } from "react-router-dom"

type Params = {
  id: string
}
//TODO：タグにPriceを付加して取得
type NewsType = {
  title: {
    rendered: string
  }
  content: {
    rendered: string
  }
  _embedded: {
    "wp:featuredmedia": [{ source_url: string }]
  }
}

const useProduct = () => {
  const [product, setProduct] = useState({
    name: "",
    explain: "",
    img: "",
  })
  const [load, setLoad] = useState<boolean>(false)
  const params: Params = useParams()
  const history = useHistory()

  useEffect(() => {
    fetch(`${WP_PRODUCT_URL}${params.id}?_embed`)
      .then((res) => res.json())
      .then((d: NewsType) => {
        //console.log(d)
        setProduct({
          name: d.title.rendered,
          explain: d.content.rendered,
          img: d._embedded["wp:featuredmedia"][0]["source_url"],
        })
        setTimeout(() => {
          setLoad(true)
        }, 1000)
      })
      .catch(() => {
        console.error()
        history.push("/")
      })
  }, [params.id, history])

  return { load, product }
}

export default useProduct
