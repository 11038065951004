import React from "react"
import ReactLoading from "react-loading"

export const BubbleLoading = () => {
  return (
    <div className="loading-box">
      <ReactLoading className="react-loading" type="spinningBubbles" />
    </div>
  )
}
