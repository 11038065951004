import { useEffect, useState } from "react"
import { WP_PRODUCTS_URL } from "../constants/url"
import { Link } from "react-router-dom"

const useProductList = () => {
  const [products, setProducts] = useState([])
  const [load, setLoad] = useState(false)
  //const [product, setProduct] = useState(false)

  useEffect(() => {
    fetch(WP_PRODUCTS_URL)
      .then((res) => res.json())
      .then((d) => {
        setProducts(d)
        setTimeout(() => {
          setLoad(true)
        }, 1000)
      })
      .catch(() => console.error())

    return () => {
      setProducts([])
      setLoad(false)
    }
  }, [])

  const titles = products.map((p, i) => {
    const POST_URL = `/products/${p["id"]}`
    const THUMB_URL =
      p["_embedded"]["wp:featuredmedia"] &&
      p["_embedded"]["wp:featuredmedia"][0]["source_url"]
    //console.log(THUMB_URL)
    //TODO: NO-IMAGE を加える（アイキャッチがあるリスト共通）
    return (
      <Link to={POST_URL} key={i}>
        <article onClick={() => {}}>
          <img src={THUMB_URL} alt={!THUMB_URL ? "no-image" : "商品画像"} />
          <h3>{p["title"]["rendered"]}</h3>
        </article>
      </Link>
    )
  })

  return { load, titles }
}

export default useProductList
