import "../styles/header.css"
import Menu from "./menu"
import torishigeLogo from "../assets/images/logo/Torishige-logo.png"
import torishigeName from "../assets/images/logo/Torishige-honten.png"
import { Link } from "react-router-dom"

const Header = () => {
  return (
    <header>
      <p>
        名古屋市千種区 <br />
        国産・地鶏専門 / 業務用卸売・小売
      </p>
      <h1>
        <Link to="/">
          <img src={torishigeLogo} alt="" className="logo" />
          <img src={torishigeName} alt="鳥重本店" className="name" />
        </Link>
      </h1>
      <Menu />
    </header>
  )
}
export default Header
