import useNewsList from "../hooks/useNews"
import "../styles/news.css"
import { BubbleLoading } from "../constants/bubbleLoading"

const News = () => {
  const { load, news } = useNewsList()

  return (
    <>
      <article id="news">
        <h2>{news.title.rendered}</h2>
        {load ? (
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: news.content.rendered }}
          ></div>
        ) : (
          <BubbleLoading />
        )}
      </article>
    </>
  )
}

export default News
