import { useState } from "react"
import { closeMenu, openMenu } from "../constants/styles"
import { Link } from "react-router-dom"
import { MENU_LIST } from "../constants/menuList"

const Menu = () => {
  const [menuState, setMenuState] = useState(false)
  const clickMenuButton = () => setMenuState(!menuState)
  const clickMenu = () => {
    setMenuState(!menuState)
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }
  const menuList = MENU_LIST.map((e, i) => {
    return (
      <div key={i}>
        <Link to={e.path} onClick={clickMenu}>
          <li>{e.name}</li>
        </Link>
      </div>
    )
  })

  return (
    <>
      <nav id="main-nav" style={menuState ? openMenu : closeMenu}>
        <ul>{menuList}</ul>
      </nav>
      <div
        className={menuState ? "menu-btn close" : "menu-btn"}
        onClick={clickMenuButton}
      >
        <div className="bar-1"></div>
        <div className="bar-2"></div>
        <div className="bar-3"></div>
      </div>
    </>
  )
}
export default Menu
