import React from "react"
import { BubbleLoading } from "../constants/bubbleLoading"
import useNewsList from "../hooks/useNewsList"

const NewsList = () => {
  const { load, titles } = useNewsList()
  return <div id="news-list">{load ? titles : <BubbleLoading />}</div>
}

export default NewsList
