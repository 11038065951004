import React from "react"
import { BubbleLoading } from "../constants/bubbleLoading"
import useProduct from "../hooks/useProduct"

const Product = () => {
  const { load, product } = useProduct()

  return (
    <>
      {load ? (
        <article id="product">
          <img src={product.img} alt="" />
          <p className="name">{product.name}</p>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: product.explain }}
          ></div>
        </article>
      ) : (
        <BubbleLoading />
      )}
    </>
  )
}

export default Product
