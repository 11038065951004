export const transition = {
  duration: 0.5,
  ease: [0.43, 0.13, 0.23, 0.96],
} as const

export const variants = {
  initial: { scale: 0.8, y: 20, opacity: 0 },
  enter: { scale: 1, y: 0, opacity: 1, transition },
  exit: {
    scale: 0.8,
    y: 100,
    opacity: 0,
    transition: { ...transition, duration: 1 },
  },
} as const
