//import Footer from "./components/footer"
import Header from "./components/header"
import Main from "./Main"
import { BrowserRouter } from "react-router-dom"
import "./styles/common.css"
import Footer from "./components/footer"

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Main />
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
