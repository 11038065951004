import { motion } from "framer-motion"
import { variants } from "../constants/framerAnimation"
const FramerMotion = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  return (
    <motion.div
      className="main"
      initial="initial"
      animate="enter"
      exit="exit"
      transition={{ duration: 2 }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

export default FramerMotion
