import React from "react"
import { BubbleLoading } from "../constants/bubbleLoading"
import useAd from "../hooks/useAd"

const Ad = () => {
  const { load_ad, ad_picture } = useAd()
  return load_ad ? (
    <>
      <h2>チラシ</h2>
      <article id="ad">
        <a href={ad_picture()} target="_blank" rel="noreferrer">
          <img src={ad_picture()} alt={!ad_picture() ? "no-image" : "チラシ"} />
        </a>
        <p>
          <small>※画像をクリック・タップすると拡大表示されます。</small>
        </p>
      </article>
    </>
  ) : (
    <BubbleLoading />
  )
}

export default Ad
