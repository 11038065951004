import { useEffect, useState } from "react"
import { WP_AD_URL } from "../constants/url"

type AdType = {
  _embedded: {
    "wp:featuredmedia": [
      {
        source_url: string
      }
    ]
  }
}

const initialAd: AdType = {
  _embedded: { "wp:featuredmedia": [{ source_url: "" }] },
}

const useAd = () => {
  const [ad, setAd] = useState<AdType>(initialAd)
  const [load_ad, setLoad] = useState(false)

  useEffect(() => {
    fetch(WP_AD_URL)
      .then((res) => res.json())
      .then((d) => {
        setAd(d)
        setTimeout(() => {
          setLoad(true)
        }, 1000)
      })
      .catch(() => console.error())

    return () => {
      setAd(initialAd)
      setLoad(false)
    }
  }, [])

  const ad_picture = () => {
    const THUMB_URL =
      ad._embedded["wp:featuredmedia"] &&
      ad._embedded["wp:featuredmedia"][0]["source_url"]
    //TODO: NO-IMAGE を加える（アイキャッチがあるリスト共通）
    return THUMB_URL
  }

  return { load_ad, ad_picture }
}

export default useAd
