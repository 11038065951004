import { useEffect, useState } from "react"
import { WP_POST_URL } from "../constants/url"
import { Link } from "react-router-dom"

const useNewsList = () => {
  const [posts, setPosts] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    fetch(WP_POST_URL)
      .then((res) => res.json())
      .then((d) => {
        setPosts(d)
        setTimeout(() => setLoad(true), 1000)
        //console.log(d)
      })
      .catch(() => console.error())

    return () => {
      setPosts([])
      setLoad(false)
    }
  }, [])

  const modifyDate = (d: Date) => {
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
  }

  const titles =
    posts.length === 0 ? (
      <p>現在、お知らせはありません。</p>
    ) : (
      posts.map((p, i) => {
        const POST_URL = `/news/${p["id"]}`
        const THUMB_URL =
          p["_embedded"] && p["_embedded"]["wp:featuredmedia"][0]["source_url"]
        const D = new Date(p["date"])

        return (
          <Link
            to={POST_URL}
            key={i}
            onClick={() =>
              window.scroll({ top: 0, left: 0, behavior: "smooth" })
            }
          >
            <article>
              <p className="date">{modifyDate(D)}</p>
              {THUMB_URL && <img src={THUMB_URL} alt="記事サムネイル" />}
              <h3>{p["title"]["rendered"]}</h3>
            </article>
          </Link>
        )
      })
    )
  return { load, titles }
}

export default useNewsList
