export const MAP_TORISHIGE = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d421.3595699669534!2d136.9280554548656!3d35.16412279768761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003709313ebd7e1%3A0xcd75cd291af93c02!2z77yI5qCq77yJ6bOl6YeN5pys5bqX!5e0!3m2!1sja!2sjp!4v1632895261609!5m2!1sja!2sjp"
      className="map"
      loading="lazy"
      title="map-torishige"
    ></iframe>
  )
}
export const MAP_SEN_NO_TORI = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d421.3595699669534!2d136.9280554548656!3d35.16412279768761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003709313ebd7e1%3A0xcd75cd291af93c02!2z77yI5qCq77yJ6bOl6YeN5pys5bqX!5e0!3m2!1sja!2sjp!4v1632895261609!5m2!1sja!2sjp"
      className="map"
      loading="lazy"
      title="map-torishige"
    ></iframe>
  )
}
