import { BubbleLoading } from "../constants/bubbleLoading"
import useNewsList from "../hooks/useNewsList"
import { Link } from "react-router-dom"
const Posts = () => {
  const { load, titles } = useNewsList()

  return (
    <div id="posts">
      <h2>最新のお知らせ</h2>
      {load ? (
        <>
          {titles}
          <Link to="/news/">
            <p className="to-news-list">
              <small>お知らせ一覧へ</small>
            </p>
          </Link>
        </>
      ) : (
        <BubbleLoading />
      )}
    </div>
  )
}

export default Posts
