import { Link } from "react-router-dom"
import Torishige_Logo_White from "../assets/images/logo/Torishige-logo-white.png"
import { MAP_TORISHIGE } from "../constants/iframes"

const Footer = () => {
  return (
    <footer>
      <div className="info">
        <p>定休日：日曜日 / 月曜日（祝）</p>
        <p>
          〒464-0858 <br />
          愛知県名古屋市千種区千種1-14-25
        </p>
        <p>
          営業時間：10:30 ~ 18:30 <br />
          電話番号：052-733-1129
        </p>
      </div>
      <div id="map">
        <MAP_TORISHIGE />
      </div>
      <Link to="/">
        <img src={Torishige_Logo_White} alt="" />
        <span>©︎（株）鳥重本店 2021</span>
      </Link>
      <Link to="/info/">
        <p className="info">特定商取引法に関する表示</p>
      </Link>
    </footer>
  )
}
export default Footer
